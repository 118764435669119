<template>
  <div class="playbar">
    <div class="playwrap">
      <!-- 音乐封面 -->
      <div class="audio_cover" @click="setPlayStatus">
<!--        v-if="musicInfo.type === 2"-->
        <el-image v-if="musicInfo && musicInfo.logo"  :src="musicInfo && musicInfo.logo ? musicInfo.logo : cover_url" fit="fill">
        </el-image>
        <el-image v-if="musicInfo.type === 3" :src="cover_url" fit="fill">
        </el-image>
        <div class="cover_cont">
          <img :src="require(`@/assets/svg/${playing ? 'play' : 'pause'}-audio-icon.svg`)" />
        </div>
      </div>
      <!-- 音乐信息 -->
      <div class="audio_info">
        <div class="audio_name">{{ musicInfo.name }}</div>
        <div class="audio_slider">
          <div class="block">
            <el-slider
              v-model="currentTime"
              :show-tooltip="false"
              :min="0"
              :step="0.1"
              :max="Number(musicInfo.duration) || 0"
              @touchstart.native="mousedown"
              @change="handleProgressChange"/>
          </div>
          <div class="duration_info">{{ currentTime | formatTimeBySecond }}/{{ musicInfo.duration | formatTimeBySecond }}</div>
        </div>
      </div>
      <audio
        ref="playbar"
        preload
        :src="musicInfo.watermark_file"
        @ended="ended"
        @timeupdate="timeupdate"
        @durationchange="loaddata"
        @error="error">
      </audio>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    musicInfo:{
      type: Object,
      default: null
    }
  },
  watch:{
    musicInfo:{
      handler(){
        if(this.playRef){
          this.playing = false;
          this.currentTime = 0;
          this.playRef.currentTime = 0;
          this.playRef.pause();
          setTimeout(()=>{
            this.playRef.play();
            this.playing = true;
            this.$emit("updateStatus", true);
          },30)
        }
      },
      immediate: true
    },
  },
  data() {
    return {
      cover_url: require("@/assets/image/mobile/music_cover.jpg"),
      playing: false,
      playRef: null,
      currentTime: 0,
      progress: 0
    };
  },
  mounted() {
    if(this.musicInfo){
      this.play();
    }
  },
  methods:{
    play(){
      this.$nextTick(()=>{
        let playRef = this.$refs.playbar;
        this.playRef = playRef;
      })
    },
    // 更改播放状态
    setPlayStatus(){
      this.playing = !this.playing;
      if(this.playRef){
        !this.playing ? this.playRef.pause() : this.playRef.play();
        this.$emit("updateStatus", this.playing);
      }
    },
    // 播放结束
    ended(){
      this.playRef && this.playRef.pause();
      this.playRef.currentTime = 0;
      this.currentTime = 0;
      this.playing = false;
      this.$emit("updateStatus", false);
    },
    timeupdate(){
      if(this.playRef){
        this.currentTime = parseInt(this.playRef.currentTime);
      }
    },
    loaddata(){
      if(this.playRef && !this.playing){
        this.playRef.play();
        this.playing = true;
        this.$emit("updateStatus", true);
      }
    },
    error(){
      this.$message.warning("音乐播放错误!")
    },
    mousedown(){
      this.playRef && this.playRef.pause();
    },
    handleProgressChange(e){
      if(this.playRef){
        this.playRef.play();
        this.playRef.currentTime = e;
        this.playing = true;
        this.$emit("updateStatus", true);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.playbar{
  position:fixed;
  bottom: 0;
  width: 100%;
  height: 58px;
  background: #FFFFFF;
  //box-shadow: 0rem -0.06rem .24rem 0rem rgba(0,0,0,0.09);
  padding: 8px 20px;
  box-sizing: border-box;
  z-index: 10;
  overflow: hidden;
  .playwrap{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .audio_cover{
    flex: 0 0 42px;
    height: 42px;
    background-size: contain;
    position: relative;
    border-radius: 2px;
    position: relative;

    .cover_cont{
      z-index: 1000;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.2);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      position: absolute;
      top: 0;
      left: 0;
      img{
        display: inline-block;
        width: 14.09px;
        height: 16.24px;
      }
    }
  }
  .audio_info{
    flex: 1;
    margin-left: 12px;
    overflow: hidden;
    .audio_name{
      width: 100%;
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(51,51,51,0.86);
      line-height: 18px;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .audio_slider{
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      .block{
        flex: 1;
        /deep/.el-slider__runway{
          height: 2px;
          margin: 0 !important;
          background: #282828;
          border-radius: 1px;
          .el-slider__button-wrapper{
            top: -17px !important;
          }
          .el-slider__bar{
            height: 2px;
            background: #E52A0D;
            border-radius: 1px;
          }
          .el-slider__button{
            width: 4px !important;
            height: 4px !important;
            background: #E52A0D !important;
            border: none !important;
          }
        }
      }
      .duration_info{
        padding-left: 12px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51,51,51,0.6);
        line-height: 14px;
      }
    }
  }
}
/deep/{
  .el-image{
    width: 100%;
    height: 100%;
  }
 }
</style>
